import randomColor from "randomcolor"

import { AudioEntry } from "../types"

export default class AudioFactory {
  static create(): AudioEntry {
    return {
      id: undefined,
      name: "",
      source: "",
      keypress: "",
      volume: 100,
      color: randomColor()
    }
  }
}
